.header{
  position: absolute;
  background-color: #f3f4f6;
  width: 100%;
}

.header-container {
  display: flex;
  justify-content: center;
  height: 120px;
  color: black;
}

.header-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 70%;
  color: black;
}

.header-logo {
  display: flex;
  width: 170px; 
  height: 76px;
  margin-right: 20px;
}

.header-option {
  font-size: 100%;
  cursor: pointer;
  font-weight: 600;
}

.header-hamburger {
  display: none;
  color: black;
}

.header-option-seperator {
  margin: 0 25px 0 0;
  color: black;
}

.selected-header-option {
  color: brown;
}

@media only screen and (max-width: 1110px) {
  .header-parent {
    width: 80%;
  }
}

@media only screen and (max-width: 970px) {
  .header-hamburger {
    display: block;
    margin-left: 50px;
  }
  .header-parent {
    width: 100%;
  }

  .header-logo {
    display: block;
    margin: 0 auto;
    width: 170px; 
    height: 76px;
  }

  .header-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -100%;
    top: 0px;
    height: 100vh;
    width: 50%;
    padding-left: 15px;
    justify-content: space-around;
    background-color: antiquewhite;
    z-index: 1000;
    opacity: 0.8;
    transition: all 0.8s ease-out;
  }
  .header-option-seperator {
    margin: 0;
  }
  .selected-header-option {
    color: black;
  }
  .header-option {
    color: black;
  }
  .header-hamburger-bars {
    font-size: 200%;
    cursor: pointer;
    margin: 0px 10%;
    color: black;
  }
  .show-hamburger-options {
    left: 0;
    opacity: 1;
  }
}
