.ack-parent {
    align-content: center;
}

.ack-title {
    font-family: Poppins Bold;
    font-size: 30px;
    position: relative;
    left: 45%;
    margin: 5px 0 5px 0;
}

.items {
    margin: 50px;
}

.ack-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}