.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 50px 0;
}

.screen-sub-heading {
  letter-spacing: 3px;
  margin: 8px 0 18px 0;
  font-size: 12px;
  color: black;
}

.screen-heading {
  font-size: 30px;
  color: black;
  font-family: "Poppins Bold";
}

.heading-seperator {
  display: flex;
  align-items: center;
  position: relative;
  width: 180px;
  margin: 10px 0 0 0;
}

.seperator-line {
  width: 100%;
  height: 2px;
  background-color: black;
}

.seperator-blob {
  height: 10px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.seperator-blob div {
  width: 35px;
  border-radius: 10px;
  background-color: rgba(196, 129, 5, 0.849);
}
