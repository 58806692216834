.research-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
}

.research-parent {
  max-width: 1000px;
  width: 70%;
}

.research-projects {
  width: 100%;
  box-shadow: 0 0px 20px -2px #f5a107;
  position: relative;
}

.project-image {
  float: right;
  width: 50%;
  margin: 20px 0 20px 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.project-title {
  font-family: Poppins SemiBold;
  font-size: larger;
  margin: 40px 0 0 0;
}

.project-details {
  /* float:right; */
  width: 48%;
  text-align: left;
  padding: 30px;
  font-family: Poppins Regular;
}

.bottom-bar {
  height: 40px;
  line-height: 27px;
  border-bottom: 1px solid rgb(229, 229, 229);
  background: none repeat scroll 0% 0% rgb(252, 252, 252);
}

@media screen and (max-width: 970px) {
  .project-title {
    padding-top: 15px;
    margin-left: 10px;
  }

  .project-image {
    width: 100%;
    margin: 20px 0 20px 10px;
  }

  .project-details {
    width: 100%;
    padding: 20px;
  }

}