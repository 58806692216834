.vi-parent {
    display: flex;
}

.row {
    display: flex;
}

.column {
    flex: 30%;
    padding: 50px;
    margin: 0 0 0 100px;
}