.forward {
  font-family: Poppins Regular;
  font-size: 18px;
  width: 80%;
  position: relative;
  margin: 0 auto;
}

.content {
  font-family: Poppins Regular;
  font-size: 18px;
  width: 80%;
  position: relative;
  margin: 20px auto;
}