.profile-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 10%;
  min-height: fit-content;
  margin-top: 100px;
}

.profile-parent {
  /* color of those text */
  display: flex;
  align-items: center;
  color: rgb(10,90,64);
}

.profile-details-name {
  /* Welcome Text */
  font-size: 18px;
  font-family: "Poppins SemiBold";
}

.profile-details-role {
  /* Welcome Text */
  display: flex;
  flex-direction: column;
  margin: 14px 0 24px 0;
}

.profile-details-role h1 {
  font-family: Poppins Bold;
  font-size: 40px;
}

.primary-text {
  font-family: Poppins Bold;
  color: (--white);
}

.profile-role-tagline {
  /* Formal Description */
  display: block;
  font-size: 18px;
  float: left; 
  /* margin-right: 100px; */
  font-family: Poppins SemiBold;
  width: 600px;
  height: 300px; 
  text-align:justify;
  margin: auto;
}

/* .profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0px 0px var(--white);
  border-radius: 50%;
  height: 300px;
  width: 300px;
  margin: 0 100px 0 0px;
  background-size: cover;
} */

.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0px 0px var(--white);
  border-radius: 100%;
  height: 300px;
  width: 300px;
  margin: 0 100px 0 0px;
  background-size: cover;
}

/* For ipad pro*/
@media screen and (max-width: 1110px) {
 

  .profile-details-name {
    font-size: 20px;
  }

  .profile-details-role .PRIMARY-TEXT {
    font-size: 12px;
  }

}

@media screen and (max-width: 970px) {

  .profile-parent{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .profile-picture {
    height: auto;
    width: 100%;
  }

  .profile-role-tagline {
    /* Formal Description */
    display: block;
    float: left;
    width: 100%;
  }

}