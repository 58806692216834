/*
.people-list {
  width: 80%;
  position: relative;
  margin: 0 auto;
}
*/

.people-list {
  width: 50%;
  position: relative;
}

.people-content {
  font-family: Poppins SemiBold;
  font-size: 18px;
}

.people-title {
  font-family: Poppins Medium;
  font-size: 16px;
}

div.gallery-container {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  margin-left: 40px;
  margin-right: 40px;
}

/* These two classes seem to have no effect */
div.gallery {
  border: 0px solid #ccc;
}
/* These two classes seem to have no effect */
div.gallery:hover {
  border: 0px solid #777;
}

div.gallery img {
  width: 50%;
  height: auto;
  margin-bottom:5px;
  position: relative; 
  top:-50%;left:+25%;
  vertical-align:middle;
}

a.desc {
  padding: 15px;
  text-align: center;
  display: block;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 1000px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}