.parent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;
    padding: 0;
}

.header {
  /* display: flex; */
  position: absolute;
  z-index: 5555;
  /* margin: 0 0 0 0; */
  /* flex-direction: column; */
}

.lab-container {
  /* display: flex; */
  /* flex-direction: column; */
  margin: auto;
  position: relative;
}

.screen {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
}
