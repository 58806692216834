.news-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 800px; */
}

.news {
    font-family: Poppins SemiBold;
    font-size: 18px;
    width: 80%;
    position: relative;
    margin: 0 auto;
  }